import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type ProviderQueryMemoizer = QueryMemoizer<
    ProviderQueryState, ProviderQueryEventUnion, []
>

export type ProviderQuery = Query<ProviderQueryState, ProviderQueryEventUnion>

export function createProviderQueryMemoizer(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): ProviderQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState: initialProviderQueryState,
        reduce: reduceProviderQueryState,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 1);
    return memoizer;

    async function* source(
        signal: AbortSignal,
    ) {
        const source = await services.backend.getProviders({
            parameters: {},
        });

        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type ProviderQueryEventUnion =
    adminApi.ProviderSnapshotEventSchema |
    adminApi.ProviderAddedEventSchema |
    adminApi.ProviderDeletedEventSchema |
    adminApi.ProviderUpdatedEventSchema;

export interface ProviderQueryEntity {
    name: string;
    description: string;
    pollingInterval: number;
}

export interface ProviderQueryState {
    entities: immutable.Map<number, ProviderQueryEntity>;
}

export const initialProviderQueryState: ProviderQueryState = {
    entities: immutable.Map(),
};

export function reduceProviderQueryState(
    state: ProviderQueryState,
    event: ProviderQueryEventUnion,
): ProviderQueryState {
    switch (event.type) {
        case "provider-snapshot": {
            let { entities } = state;

            entities = entities.withMutations(mutator => {
                for (const {
                    id,
                    name,
                    description,
                    pollingInterval,
                } of event.payload.providers) {
                    mutator.set(id, {
                        name,
                        description,
                        pollingInterval,
                    });
                }
            });

            return {
                entities,
            };
        }

        case "provider-added": {
            let { entities } = state;

            const {
                id,
                name,
                description,
                pollingInterval,
            } = event.payload.provider;

            const provider = entities.get(id);
            assert(!provider, `could not add provider ${id} as it already exists`);

            entities = entities.set(id, {
                name,
                description,
                pollingInterval,
            });

            return {
                entities,
            };
        }

        case "provider-updated": {
            let { entities } = state;

            const {
                id,
                name,
                description,
                pollingInterval,
            } = event.payload.provider;

            const provider = entities.get(id);
            assert(provider, `could not update provider ${id} as it does not exist`);

            entities = entities.set(id, {
                name,
                description,
                pollingInterval,
            });

            return {
                entities,
            };
        }

        case "provider-deleted": {
            const { entities } = state;
            const { id } = event.payload.provider;

            const provider = entities.get(id);
            assert(provider, `could not delete provider ${id} as it does not exist`);

            return {
                entities: entities.delete(id),
            };
        }
    }

}

//#endregion

//#region selectors

export function createProviderSnapshotEvent(
    state: ProviderQueryState,
): ProviderQueryEventUnion {
    const providers = new Array<
        // eslint-disable-next-line max-len
        adminApi.ProviderSnapshotEventPayloadProvidersItemsSchema
    >();

    const providerKeys = state.entities.keySeq().toArray();
    for (const key of providerKeys) {
        const { name, description, pollingInterval } = state.entities.get(key)!;

        providers.push({
            id: key,
            name,
            description,
            pollingInterval,
        });
    }

    return {
        type: "provider-snapshot",
        payload: {
            providers,
        },
    };
}

export function selectProviderList(state: ProviderQueryState) {
    return [
        ...state.entities.
            map((value, key) => mapEntity(key, value)).
            sortBy(value => value.id).
            values(),
    ];
}

function mapEntity(id: number, entity: ProviderQueryEntity) {
    return {
        id,
        ...entity,
    };
}

//#endregion
