import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import { html, nothing, PropertyValues } from "lit";
import { BusyController, FormController } from "../../../controllers/index.js";
import { FieldValidators, isIterableEmpty } from "../../../utils/index.js";
import { ComponentBase } from "../../base.js";

type Model =
    adminApi.OrganizationAddCommandApplicationJsonRequestBodyOrganizationSchema

const members = [
    "id",
    "name",
] as Array<keyof Model>;

const fieldValidators = {
    // eslint-disable-next-line max-len
    ...adminApi.validateOrganizationAddCommandApplicationJsonRequestBodyOrganizationSchema.properties,
} as FieldValidators<Model>;

function hasError(model: Partial<Model>, member: keyof Model): boolean {
    const fieldValidator = fieldValidators[member];
    return model[member] != null && !isIterableEmpty(fieldValidator(model[member]));
}

export class OrganizationCreateComponent extends ComponentBase {
    private busy = new BusyController(this);

    private form = new FormController<Model>(
        this,
        members,
        hasError,
    )

    protected getModel() {
        return {};
    }

    render() {
        const { isBusy } = this.busy;
        const { model, errors } = this.form;

        if (model == null || errors == null) return nothing;

        return html`
<fieldset>

<app-text-field
    title="ID"
    data-member="id"
    .value=${model.id}
    ?error=${errors.id}
    trim
    required
></app-text-field>

<app-text-field
    title="Name"
    data-member="name"
    .value=${model.name}
    ?error=${errors.name}
    trim
    required
></app-text-field>

</fieldset>

<app-action-bar>
<app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
    <app-icon type="thumb_up"></app-icon>
    Ok
</app-clickable-action>
<app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
    <app-icon type="undo"></app-icon>
    Cancel
</app-clickable-action>
</app-action-bar>
`;
    }

    private handleSubmit = this.busy.wrap(async () => {
        const { model } = this.form;
        assert(model);

        this.form.touch();
        if (!this.form.isValid()) return;

        const result = await applicationContext.services.backend.organizationAddCommand({
            parameters: {},
            entity: () => {
                return {
                    // eslint-disable-next-line max-len
                    organization: model as adminApi.OrganizationAddCommandApplicationJsonRequestBodyOrganizationSchema,
                };
            },
        });

        assert(result.status === 204);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

    update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (this.form.model != null) return;

        const model = this.getModel();
        this.form.setModel(model);
    }
}
