import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type LocationQueryMemoizer = QueryMemoizer<
    LocationQueryState, LocationQueryEventUnion, []
>

export type LocationQuery = Query<LocationQueryState, LocationQueryEventUnion>

export function createLocationQueryMemoizer(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): LocationQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 0);

    return memoizer;

    async function* source(
        signal: AbortSignal,
    ) {
        const source = await services.backend.locationQuery({
            parameters: {},
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type LocationQueryEventUnion =
    adminApi.LocationInitializedSchema |
    adminApi.LocationAddedSchema |
    adminApi.LocationRemovedSchema |
    adminApi.LocationUpdatedSchema;

export interface LocationQueryEntity {
    name: string;
    geoLocation: string;
    provider: string | undefined;
    type: string,
    config: object,
    limits: Record<string, { hard: number, soft: number }>,
    advantages: Record<string, number>,
    machineLingerInterval?: number,
    machineReadyInterval?: number,
    dynamic?: boolean,
}

export interface LocationQueryState {
    entities: immutable.Map<string, LocationQueryEntity>;
}

const initialState: LocationQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: LocationQueryState,
    event: LocationQueryEventUnion,
): LocationQueryState {
    switch (event.type) {
        case "location-initialized": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const location of event.payload.locations) {
                const {
                    id,
                    name,
                    geoLocation,
                    provider,
                    type,
                    config,
                    limits,
                    advantages,
                    machineLingerInterval,
                    machineReadyInterval,
                    dynamic,
                } = location;
                const entity = {
                    name,
                    geoLocation,
                    provider,
                    type,
                    config,
                    limits,
                    advantages,
                    machineLingerInterval,
                    machineReadyInterval,
                    dynamic,
                };
                entities.set(id, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "location-added": {
            let { entities } = state;

            const {
                id,
                name,
                geoLocation,
                provider,
                type,
                config,
                limits,
                advantages,
                machineLingerInterval,
                machineReadyInterval,
                dynamic,
            } = event.payload.location;
            const entity = {
                name,
                geoLocation,
                provider,
                type,
                config,
                limits,
                advantages,
                machineLingerInterval,
                machineReadyInterval,
                dynamic,
            };

            assert(!entities.get(id));
            entities = entities.set(id, entity);

            return {
                ...state,
                entities,
            };
        }

        case "location-updated": {
            let { entities } = state;

            const {
                id,
                name,
                geoLocation,
                provider,
                config,
                limits,
                advantages,
                machineLingerInterval,
                machineReadyInterval,
                dynamic,
            } = event.payload.location;

            let entity = entities.get(id);
            assert(entity);
            entity = {
                ...entity,
                name,
                geoLocation,
                provider,
                config,
                limits,
                advantages,
                machineLingerInterval,
                machineReadyInterval,
                dynamic,
            };

            entities = entities.set(id, entity);

            return {
                ...state,
                entities,
            };
        }

        case "location-removed": {
            let { entities } = state;

            const { id } = event.payload.location;

            assert(entities.has(id));

            entities = entities.delete(id);

            return {
                ...state,
                entities,
            };
        }
    }

}

//#endregion

//#region selectors

export function selectLocationList(state: LocationQueryState) {
    return [
        ...state.entities.
            map((value, key) => mapEntity(key, value)).
            sortBy(value => value.location).
            values(),
    ];
}

export function selectLocationItem(
    state: LocationQueryState,
    location: string,
) {
    const entity = state.entities.get(location);
    if (!entity) return;

    return mapEntity(location, entity);
}

export function selectLocationName(state: LocationQueryState, location: string) {
    return selectLocationItem(state, location)?.name;
}

function mapEntity(location: string, entity: LocationQueryEntity) {
    return {
        location,
        ...entity,
    };
}

//#endregion
