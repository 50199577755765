import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import { html, nothing, PropertyValues } from "lit";
import { property } from "lit/decorators.js";
import { BusyController, FormController, QueryValueController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { FieldValidators, isIterableEmpty } from "../../../utils/index.js";
import { ComponentBase } from "../../base.js";

type Model =
    adminApi.UpdateImageApplicationJsonRequestBodySchema

const members = [
    "name",
    "registry",
    "repository",
    "overcapacity",
    "resources",
    "ports",
    "portForwarding",
    "imageReadyInterval",
    "lookbackInterval",
    "metricsPath",
    "defaultArgs",
    "defaultEnv",
    "sessionLingerInterval",
    "sessionSlotCapacity",
    "sessionSlotBuffer",
    "tagKeep",
    "imageBuffer",
] as Array<keyof Model>;

const fieldValidators = {
    ...adminApi.validateUpdateImageApplicationJsonRequestBodySchema.properties,
} as FieldValidators<Model>;

function hasError(model: Partial<Model>, member: keyof Model): boolean {
    const fieldValidator = fieldValidators[member];
    return model[member] != null && !isIterableEmpty(fieldValidator(model[member]));
}

export class ImageEditComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    image!: string

    private busy = new BusyController(this);

    private form = new FormController<Model>(
        this,
        members,
        hasError,
    )

    private queryValue = new QueryValueController(
        this,
        applicationContext.queries.image,
        applicationSettings.linger,
        q.selectImageItem,
        () => [this.image] as const,
    );

    private getModel() {
        if (this.queryValue.value == null) return;

        const { image } = this;
        const {
            name,
            registry,
            repository,
            overcapacity,
            resources,
            ports,
            portForwarding,
            imageReadyInterval,
            lookbackInterval,
            metricsPath,

            defaultArgs,
            defaultEnv,
            sessionLingerInterval,
            sessionSlotCapacity,
            sessionSlotBuffer,
            tagKeep,
            imageBuffer,
        } = this.queryValue.value;

        const model = {
            image,
            name,
            registry,
            repository,
            overcapacity,
            resources,
            ports,
            portForwarding,
            imageReadyInterval,
            lookbackInterval,
            metricsPath,
            defaultArgs,
            defaultEnv,
            sessionLingerInterval,
            sessionSlotCapacity,
            sessionSlotBuffer,
            tagKeep,
            imageBuffer,
        };
        return model;
    }

    render() {
        const { isBusy } = this.busy;
        const { model, errors } = this.form;

        if (this.queryValue.loading) return html`<app-loading></app-loading>`;
        if (model == null || errors == null) return nothing;

        return html`
<fieldset>
    
<app-text-field
    title="Name"
    data-member="name"
    .value=${model.name}
    ?error=${errors.name}
    trim
    required
></app-text-field>

<app-text-field
    title="Registry"
    data-member="registry"
    .value=${model.registry}
    ?error=${errors.registry}
    trim
    required
></app-text-field>

<app-text-field
    title="Repository"
    data-member="repository"
    .value=${model.repository}
    ?error=${errors.repository}
    trim
    required
></app-text-field>

<app-interval-field
    title="Image Ready Interval"
    data-member="imageReadyInterval"
    .value=${model.imageReadyInterval}
    ?error=${errors.imageReadyInterval}
></app-interval-field>

<app-interval-field
    title="Lookback Interval"
    data-member="lookbackInterval"
    .value=${model.lookbackInterval}
    ?error=${errors.lookbackInterval}
></app-interval-field>

<app-text-field
    title="Metrics scraping url"
    data-member="metricsPath"
    .value=${model.metricsPath}
    ?error=${errors.metricsPath}
></app-text-field>

<app-json-field
    title="Overcapacity"
    data-member="overcapacity"
    .value=${model.overcapacity}
    ?error=${errors.overcapacity}
    required
></app-json-field>

<app-json-field
    title="Resources"
    data-member="resources"
    .value=${model.resources}
    ?error=${errors.resources}
    required
></app-json-field>

<app-json-field
    title="Ports"
    data-member="ports"
    .value=${model.ports}
    ?error=${errors.ports}
></app-json-field>

<app-boolean-field
    title="Port forwarding"
    data-member="portForwarding"
    .value=${model.portForwarding ?? false}
    ?error=${errors.portForwarding}
></app-boolean-field>

<app-json-field
    title="default program arguments"
    data-member="defaultArgs"
    .value=${model.defaultArgs}
    ?error=${errors.defaultArgs}
></app-json-field>

<app-json-field
    title="default program environment"
    data-member="defaultEnv"
    .value=${model.defaultEnv}
    ?error=${errors.defaultEnv}
></app-json-field>


<app-interval-field
    title="session linger interval"
    data-member="sessionLingerInterval"
    .value=${model.sessionLingerInterval}
    ?error=${errors.sessionLingerInterval}
></app-interval-field>

<app-number-field
    title="session slot capacuty"
    data-member="sessionSlotCapacity"
    .value=${model.sessionSlotCapacity}
    ?error=${errors.sessionSlotCapacity}
></app-number-field>

<app-number-field
    title="session slot buffer"
    data-member="sessionSlotBuffer"
    .value=${model.sessionSlotBuffer}
    ?error=${errors.sessionSlotBuffer}
></app-number-field>

<app-number-field
    title="tag keep"
    data-member="tagKeep"
    .value=${model.tagKeep}
    ?error=${errors.tagKeep}
></app-number-field>

<app-number-field
    title="image buffer"
    data-member="imageBuffer"
    .value=${model.imageBuffer}
    ?error=${errors.imageBuffer}
></app-number-field>

</fieldset>

<app-action-bar>
<app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
    <app-icon type="thumb_up"></app-icon>
    Ok
</app-clickable-action>
<app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
    <app-icon type="undo"></app-icon>
    Cancel
</app-clickable-action>
</app-action-bar>
`;
    }

    private handleSubmit = this.busy.wrap(async () => {
        const { model } = this.form;
        assert(model);

        this.form.touch();
        if (!this.form.isValid()) return;

        const {
            name,
            registry,
            repository,
            overcapacity,
            resources,
            ports,
            portForwarding,
            imageReadyInterval,
            lookbackInterval,
            metricsPath,

            defaultArgs,
            defaultEnv,
            sessionLingerInterval,
            sessionSlotCapacity,
            sessionSlotBuffer,

            tagKeep,
            imageBuffer,
        } = model as Model;

        const result = await applicationContext.services.backend.updateImage({
            parameters: {
                image: this.image,
            },
            entity: () => {
                return {
                    name,
                    registry,
                    repository,
                    overcapacity,
                    resources,
                    ports,
                    portForwarding,
                    imageReadyInterval,
                    lookbackInterval,
                    metricsPath,

                    defaultArgs,
                    defaultEnv,
                    sessionLingerInterval,
                    sessionSlotCapacity,
                    sessionSlotBuffer,

                    tagKeep,
                    imageBuffer,
                };
            },
        });

        assert(result.status === 204);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

    update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (this.form.model != null) return;

        const model = this.getModel();
        if (model == null) return;

        this.form.setModel(model);
    }
}
