import * as adminApi from "@gameye/admin-api-spec";
import * as oas3ts from "@oas3/oas3ts-lib";
import { authorizationClientMiddleware } from "../utils/index.js";
import { Settings } from "./settings";

export interface Services {
    backend: adminApi.Client;
}

export function createServices(
    settings: Settings,
    accessToken: string,
): Services {
    const backend = new adminApi.Client({
        baseUrl: settings.backendEndpoint,
        httpSendReceive: settings.httpSendReceive,
        validateIncomingEntities: false,
    }, {
        accessToken,
    });
    backend.registerMiddleware(
        oas3ts.createRedirectMiddleware(),
    );
    backend.registerMiddleware(
        authorizationClientMiddleware(settings),
    );

    return {
        backend,
    };
}
