import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import { html, nothing, PropertyValues } from "lit";
import { property } from "lit/decorators.js";
import { BusyController, FormController, QueryValueController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { FieldValidators, isIterableEmpty } from "../../../utils/index.js";
import { ComponentBase } from "../../base.js";

type Model =
    adminApi.UpdateRegionApplicationJsonRequestBodySchema

const members = [
    "names",
    "parents",
    "latlon",
] as Array<keyof Model>;

const fieldValidators = {
    ...adminApi.validateUpdateRegionApplicationJsonRequestBodySchema.properties,
} as FieldValidators<Model>;

function hasError(model: Partial<Model>, member: keyof Model): boolean {
    const fieldValidator = fieldValidators[member];
    return model[member] != null && !isIterableEmpty(fieldValidator(model[member]));
}

export class RegionEditComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    region!: string

    private busy = new BusyController(this);

    private form = new FormController<Model>(
        this,
        members,
        hasError,
    )

    private queryValue = new QueryValueController(
        this,
        applicationContext.queries.region,
        applicationSettings.linger,
        q.selectRegionItem,
        () => [this.region] as const,
    );

    private getModel() {
        if (this.queryValue.value == null) return;

        const { region } = this;
        const {
            names,
            parents,
            latlon,
        } = this.queryValue.value;

        const model = {
            names,
            parents,
            latlon: latlon ?? ["0", "0"],
        };
        return model;
    }

    render() {
        const { isBusy } = this.busy;
        const { model, errors } = this.form;

        if (this.queryValue.loading) return html`<app-loading></app-loading>`;
        if (model == null || errors == null) return nothing;

        return html`
<fieldset>
    
<app-json-field
    title="Names"
    data-member="names"
    .value=${model.names}
    ?error=${errors.names}
    trim
    required
></app-json-field>

<app-json-field
    title="Parents"
    data-member="parents"
    .value=${model.parents}
    ?error=${errors.parents}
    trim
    required
></app-json-field>

<app-json-field
    title="Latitude-and longitude"
    data-member="latlon"
    .value=${model.latlon}
    ?error=${errors.latlon}
    trim
    required
></app-json-field>

</fieldset>

<app-action-bar>
<app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
    <app-icon type="thumb_up"></app-icon>
    Ok
</app-clickable-action>
<app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
    <app-icon type="undo"></app-icon>
    Cancel
</app-clickable-action>
</app-action-bar>
`;
    }

    private handleSubmit = this.busy.wrap(async () => {
        const { model } = this.form;
        assert(model);

        this.form.touch();
        if (!this.form.isValid()) return;

        const result = await applicationContext.services.backend.updateRegion({
            parameters: {},
            entity: () => {
                return {
                    id: this.region,
                    parents: model.parents,
                    names: model.names,
                    latlon: model.latlon,
                } as adminApi.UpdateRegionApplicationJsonRequestBodySchema;
            },
        });

        assert(result.status === 204);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

    update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (this.form.model != null) return;

        const model = this.getModel();
        if (model == null) return;

        this.form.setModel(model);
    }
}
