import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type OrganizationQueryMemoizer = QueryMemoizer<
    OrganizationQueryState, OrganizationQueryEventUnion, []
>

export type OrganizationQuery = Query<OrganizationQueryState, OrganizationQueryEventUnion>

export function createOrganizationQueryMemoizer(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): OrganizationQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 0);

    return memoizer;

    async function* source(
        signal: AbortSignal,
    ) {
        const source = await services.backend.organizationQuery({
            parameters: {},
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type OrganizationQueryEventUnion =
    adminApi.OrganizationInitializedSchema |
    adminApi.OrganizationAddedSchema |
    adminApi.OrganizationArchivedSchema |
    adminApi.OrganizationUpdatedSchema;

export interface OrganizationQueryEntity {
    name: string;
}

export interface OrganizationQueryState {
    entities: immutable.Map<string, OrganizationQueryEntity>;
}

const initialState: OrganizationQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: OrganizationQueryState,
    event: OrganizationQueryEventUnion,
): OrganizationQueryState {
    switch (event.type) {
        case "organization-initialized": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const organization of event.payload.organizations) {
                const { id, name } = organization;

                const entity = {
                    name,
                };
                entities.set(id, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "organization-added": {
            let { entities } = state;

            const { id, name } = event.payload.organization;
            const entity = {
                name,
            };

            assert(!entities.get(id));
            entities = entities.set(id, entity);

            return {
                ...state,
                entities,
            };
        }

        case "organization-updated": {
            let { entities } = state;

            const { id, name } = event.payload.organization;

            let entity = entities.get(id);
            assert(entity);
            entity = {
                ...entity,
                name,
            };

            entities = entities.set(id, entity);

            return {
                ...state,
                entities,
            };
        }

        case "organization-archived": {
            let { entities } = state;

            const { id } = event.payload.organization;

            assert(entities.has(id));

            entities = entities.delete(id);

            return {
                ...state,
                entities,
            };
        }
    }

}

//#endregion

//#region selectors

export function selectOrganizationList(state: OrganizationQueryState) {
    return [
        ...state.entities.
            map((value, key) => mapEntity(key, value)).
            sortBy(value => value.organization).
            values(),
    ];
}

export function selectOrganizationItem(
    state: OrganizationQueryState,
    organization: string,
) {
    const entity = state.entities.get(organization);
    if (!entity) return;

    return mapEntity(organization, entity);
}

function mapEntity(organization: string, entity: OrganizationQueryEntity) {
    return {
        organization,
        ...entity,
    };
}

//#endregion
