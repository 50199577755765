import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import { html, nothing, PropertyValues } from "lit";
import { property } from "lit/decorators.js";
import { BusyController, FormController } from "../../../controllers/index.js";
import { queryValue } from "../../../directives/index.js";
import * as q from "../../../queries/index.js";
import { FieldValidators, isIterableEmpty, randomString } from "../../../utils/index.js";
import { ComponentBase } from "../../base.js";

type Model =
    adminApi.SessionRunApplicationJsonRequestBodySchema

const members = [
    "session",
    "region",
    "image",
    "env",
    "args",
    "restart",
    "labels",
    "version",
    "enableMetrics",
] as Array<keyof Model>;

const fieldValidators = {
    ...adminApi.validateSessionRunApplicationJsonRequestBodySchema.properties,
} as FieldValidators<Model>;

function hasError(model: Partial<Model>, member: keyof Model): boolean {
    const fieldValidator = fieldValidators[member];
    return model[member] != null && !isIterableEmpty(fieldValidator(model[member]));
}

export class SessionRunComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    organization!: string

    private busy = new BusyController(this);

    private form = new FormController<Model>(
        this,
        members,
        hasError,
    )

    protected getModel() {
        return {
            session: randomString(20),
            env: {},
            args: [],
            restart: false,
            labels: {},
            version: undefined,
        };
    }

    render() {
        const { isBusy } = this.busy;
        const { model, errors } = this.form;

        if (model == null || errors == null) return nothing;

        return html`
<fieldset>

<app-text-field
    title="session"
    data-member="session"
    .value=${model.session}
    ?error=${errors.session}
    trim
    required
></app-text-field>

<app-select-field
    title="region"
    data-member="region"
    .value=${model.region}
    .options=${queryValue(applicationContext.queries.region, q.selectRegionOptions)}
    ?error=${errors.region}
    required
></app-select-field>

<app-select-field
    title="image"
    data-member="image"
    .value=${model.image}
    .options=${queryValue(applicationContext.queries.image, q.selectImageOptions)}
    ?error=${errors.image}
    required
></app-select-field>

<app-select-field
    title="version"
    data-member="version"
    .value=${model.version}
    .options=${model.image == null ? [] : queryValue(applicationContext.queries.tag, q.selectTagOptions, model.image)}
    ?error=${errors.version}
></app-select-field>

<app-boolean-field
    title="Enable metrics"
    data-member="enableMetrics"
    .value=${model.enableMetrics ?? false}
    ?error=${errors.enableMetrics}
    required
></app-boolean-field>

<app-json-field
    title="env"
    data-member="env"
    .value=${model.env}
    ?error=${errors.env}
    trim
    required
></app-json-field>

<app-json-field
    title="args"
    data-member="args"
    .value=${model.args}
    ?error=${errors.args}
    trim
    required
></app-json-field>

<app-json-field
    title="labels"
    data-member="labels"
    .value=${model.labels}
    ?error=${errors.labels}
    trim
></app-json-field>

</fieldset>

<app-action-bar>
<app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
    <app-icon type="thumb_up"></app-icon>
    Ok
</app-clickable-action>
<app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
    <app-icon type="undo"></app-icon>
    Cancel
</app-clickable-action>
</app-action-bar>
`;
    }

    private handleSubmit = this.busy.wrap(async () => {
        const { model } = this.form;
        assert(model);

        this.form.touch();
        if (!this.form.isValid()) return;

        const result = await applicationContext.services.backend.sessionRun({
            parameters: {
                organization: this.organization,
            },
            entity: () => {
                return model as adminApi.SessionRunApplicationJsonRequestBodySchema;
            },
        });

        assert(result.status === 201);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

    update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (this.form.model != null) return;

        const model = this.getModel();
        this.form.setModel(model);
    }
}
