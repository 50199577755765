import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type OrganizationLocationQueryMemoizer = QueryMemoizer<
    OrganizationLocationQueryState, OrganizationLocationQueryEventUnion, [string]
>

export type OrganizationLocationQuery =
    Query<OrganizationLocationQueryState, OrganizationLocationQueryEventUnion>

export function createOrganizationLocationQueryMemoizer(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): OrganizationLocationQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 1);

    return memoizer;

    async function* source(
        signal: AbortSignal,
        organization: string,
    ) {
        const source = await services.backend.getOrganizationLocations({
            parameters: { organization },
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type OrganizationLocationQueryEventUnion =
    adminApi.OrganizationLocationSnapshotSchema |
    adminApi.OrganizationLocationAddedSchema |
    adminApi.OrganizationLocationRemovedSchema;

export type OrganizationLocationQueryEntity = {}

export interface OrganizationLocationQueryState {
    entities: immutable.Map<string, OrganizationLocationQueryEntity>;
}

const initialState: OrganizationLocationQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: OrganizationLocationQueryState,
    event: OrganizationLocationQueryEventUnion,
): OrganizationLocationQueryState {
    switch (event.type) {
        case "organization-location-snapshot": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const { location } of event.payload) {
                const entity = {};
                entities.set(location, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "organization-location-added": {
            let { entities } = state;

            const { location } = event.payload;
            const entity = {};

            assert(!entities.get(location));
            entities = entities.set(location, entity);

            return {
                ...state,
                entities,
            };
        }

        case "organization-location-removed": {
            let { entities } = state;

            const { location } = event.payload;

            assert(entities.has(location));

            entities = entities.delete(location);

            return {
                ...state,
                entities,
            };
        }

    }

}

//#endregion

//#region selectors

export function hasOrganizationLocation(state: OrganizationLocationQueryState, location: string) {
    return state.entities.has(location);
}

//#endregion
