import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type SessionLogsQueryMemoizer = QueryMemoizer<
    SessionLogsQueryState, SessionLogsQueryEventUnion, [string, string]
>

export type SessionLogsQuery = Query<SessionLogsQueryState, SessionLogsQueryEventUnion>

export function createSessionLogsQueryFactory(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): SessionLogsQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 2);

    return memoizer;

    async function* source(
        signal: AbortSignal,
        organization: string,
        session: string,
    ) {
        const source = await services.backend.getLogs({
            parameters: {
                organization,
                session,
            },
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type SessionLogsQueryEventUnion =
    adminApi.GetLogs200ApplicationJsonResponseSchema

export interface SessionLogsQueryEntity {
    stream: number;
    text: string;
}

export interface SessionLogsQueryState {
    entities: immutable.List<SessionLogsQueryEntity>;
}

const initialState: SessionLogsQueryState = {
    entities: immutable.List(),
};

function reduce(
    state: SessionLogsQueryState,
    event: SessionLogsQueryEventUnion,
): SessionLogsQueryState {
    return {
        entities: state.entities.push({
            stream: event.stream,
            text: event.payload,
        }),
    };
}

//#endregion

//#region selectors

export function selectSessionLogs(
    state: SessionLogsQueryState,
): SessionLogsQueryEntity[] {
    return state.entities.toArray();
}

//#endregion
