import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import { html, nothing, PropertyValues } from "lit";
import { property } from "lit/decorators.js";
import { BusyController, FormController, QueryValueController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

type Model = adminApi.UpdateMachineApplicationJsonRequestBodyMachineSchema

const members = [
    "ipV4Address",
    "available",
] as Array<keyof Model>;

export class MachineEditComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    location!: string

    @property({ type: String, reflect: true })
    machine!: string

    private busy = new BusyController(this);

    private form = new FormController<Model>(
        this,
        members,
        () => false,
    )

    private queryValue = new QueryValueController(
        this,
        applicationContext.queries.machine,
        applicationSettings.linger,
        q.selectMachineItem,
        () => [this.location, this.machine] as const,
    );

    protected getModel() {
        if (this.queryValue.value == null) return;

        const {
            available,
            ipV4Address,
        } = this.queryValue.value;

        return {
            ipV4Address,
            available,
        };
    }

    render() {
        const { isBusy } = this.busy;
        const { model, errors } = this.form;

        if (this.queryValue.loading) return html`<app-loading></app-loading>`;
        if (model == null || errors == null) return nothing;

        return html`

<fieldset>
        <app-text-field
    title="IP Address"
    data-member="ipV4Address"
    .value=${model.ipV4Address}
    ?error=${errors.ipV4Address}
    trim
    required
></app-text-field>

<app-boolean-field
    title="Availability"
    data-member="available"
    .value=${model.available ?? false}
    ?error=${errors.available}
></app-boolean-field>

</fieldset>

<app-action-bar>
<app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
    <app-icon type="thumb_up"></app-icon>
    Ok
</app-clickable-action>
<app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
    <app-icon type="undo"></app-icon>
    Cancel
</app-clickable-action>
</app-action-bar>
`;
    }

    private handleSubmit = this.busy.wrap(async () => {
        const { model } = this.form;
        assert(model);

        this.form.touch();
        if (!this.form.isValid()) return;

        const {
            ipV4Address,
            available,
        } = model as Model;

        const result = await applicationContext.services.backend.updateMachine({
            parameters: {},
            entity: () => {
                return {
                    machine: {
                        id: this.machine,
                        location: this.location,
                        ipV4Address,
                        available,
                    },
                };
            },
        });

        assert(result.status === 204);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

    update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (this.form.model != null) return;

        const model = this.getModel();
        if (model == null) return;

        this.form.setModel(model);
    }
}

