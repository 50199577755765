import * as oas3ts from "@oas3/oas3ts-lib";
import assert from "assert";
import * as querystring from "querystring";
import * as application from "../application/index.js";
import { randomString } from "./random.js";

interface State {
    authorizationCookie: string,
    path: string,
    historyState: object,
}
interface DialogState { dialog: string }

export function authorize() {
    let token = window.sessionStorage.getItem("authorization-token") ?? undefined;

    const url = new URL(window.location.href);
    const hash = url.hash.replace(/^#/, "");
    if (!hash) {
        return token;
    }

    window.sessionStorage.removeItem("authorization-token");

    const hashValues = querystring.parse(hash);

    token = hashValues["id_token"]?.toString();
    const error = hashValues["error"]?.toString();
    const state = JSON.parse(hashValues["state"]?.toString() || "{}") as State;
    const authorizationCookie = window.sessionStorage.getItem("authorization-cookie");
    window.sessionStorage.removeItem("authorization-cookie");
    window.history.replaceState(state.historyState, "", state.path);
    if (state.historyState && (state.historyState as DialogState)?.dialog) {
        window.history.replaceState({ "dialog": null }, "", state.path);
        window.history.pushState({ "dialog": null }, "", state.path);
    }
    if (error) {
        return;
    }

    if (state.authorizationCookie !== authorizationCookie) {
        return;
    }

    if (!token) {
        return;
    }

    window.sessionStorage.setItem("authorization-token", token);
    return token;
}

export async function login(
    settings: application.Settings,
) {
    assert(settings.oauthClientId);
    assert(settings.oauthRedirectUrl);

    const nonce = randomString(64);
    const authorizationCookie = randomString(64);
    window.sessionStorage.setItem("authorization-cookie", authorizationCookie);

    const state: State = {
        historyState: window.history.state,
        path: window.location.pathname + window.location.search,
        authorizationCookie,
    };

    const url = new URL("?", settings.oauthAuthorizationUrl);
    url.searchParams.append("client_id", settings.oauthClientId);
    url.searchParams.append("redirect_uri", settings.oauthRedirectUrl.toString());
    url.searchParams.append("response_type", "token id_token");
    url.searchParams.append("scope", "openid email");
    url.searchParams.append("state", JSON.stringify(state));
    url.searchParams.append("nonce", nonce);
    window.location.replace(url.toString());
    //pushStatehistory?
}

export function logout(settings: application.Settings) {
    assert(settings.oauthRedirectUrl);

    window.location.replace(settings.oauthRedirectUrl.toString());
}

export interface RedirectMiddlewareOptions {
    onError?: (error: unknown) => void;
}

export function authorizationClientMiddleware(
    settings: application.Settings,
): oas3ts.ClientMiddleware {

    return async function (this: oas3ts.ClientBase, request, next) {
        const response = await next(request);

        switch (response.status) {
            case 401: // Unauthorized
                login(settings); //maybe await?
                return response;

            default: return response;
        }
    };
}
