import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type RegionQueryMemoizer = QueryMemoizer<
    RegionQueryState, RegionQueryEventUnion, []
>

export type RegionQuery = Query<RegionQueryState, RegionQueryEventUnion>

export function createRegionQueryFactory(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): RegionQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 0);

    return memoizer;

    async function* source(
        signal: AbortSignal,
    ) {
        const source = await services.backend.getRegions({
            parameters: {},
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type RegionQueryEventUnion =
    adminApi.RegionSnapshotSchema |
    adminApi.RegionAddedSchema |
    adminApi.RegionRemovedSchema |
    adminApi.RegionUpdatedSchema;

export interface RegionQueryEntity {
    names: string[];
    parents: string[];
    latlon?: string[];
}

export interface RegionQueryState {
    entities: immutable.Map<string, RegionQueryEntity>;
}

const initialState: RegionQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: RegionQueryState,
    event: RegionQueryEventUnion,
): RegionQueryState {
    switch (event.type) {
        case "region-snapshot": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const item of event.payload) {
                const {
                    region,
                    names,
                    parents,
                    latlon,
                } = item;

                const entity = {
                    names,
                    parents,
                    latlon,
                };
                entities.set(region, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "region-added": {
            let { entities } = state;

            const {
                region,
                names,
                parents,
                latlon,
            } = event.payload;
            const entity = {
                names,
                parents,
                latlon,
            };

            assert(!entities.has(region));
            entities = entities.set(region, entity);

            return {
                ...state,
                entities,
            };
        }

        case "region-removed": {
            let { entities } = state;

            const { region } = event.payload;

            assert(entities.has(region));

            entities = entities.delete(region);

            return {
                ...state,
                entities,
            };
        }

        case "region-updated": {
            let { entities } = state;

            const {
                region,
                names,
                parents,
                latlon,
            } = event.payload;

            let entity = entities.get(region);
            assert(entity);
            entity = {
                ...entity,
                names,
                parents,
                latlon,
            };

            entities = entities.set(region, entity);

            return {
                ...state,
                entities,
            };
        }
    }

}

//#endregion

//#region selectors

export function selectRegionList(state: RegionQueryState) {
    return [
        ...state.entities.
            map((value, key) => mapEntity(key, value)).
            sortBy(value => value.region).
            values(),
    ];
}

export function selectRegionOptions(state: RegionQueryState) {
    return selectRegionList(state).
        map(({ region, names }) => [region, names.join(", ")] as const);
}

export function selectRegionItem(
    state: RegionQueryState,
    region: string,
) {
    const entity = state.entities.get(region);
    if (!entity) return;

    return mapEntity(region, entity);
}

export function selectRegionName(state: RegionQueryState, region: string) {
    return selectRegionItem(state, region)?.names.join();
}

function mapEntity(region: string, entity: RegionQueryEntity) {
    return {
        region,
        ...entity,
    };
}

//#endregion
