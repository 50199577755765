import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type OrganizationImageQueryMemoizer = QueryMemoizer<
    OrganizationImageQueryState, OrganizationImageQueryEventUnion, [string]
>

export type OrganizationImageQuerySource =
    Query<OrganizationImageQueryState, OrganizationImageQueryEventUnion>

export function createOrganizationImageQueryMemoizer(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): OrganizationImageQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 1);

    return memoizer;

    async function* source(
        signal: AbortSignal,
        organization: string,
    ) {
        const source = await services.backend.getOrganizationImages({
            parameters: { organization },
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type OrganizationImageQueryEventUnion =
    adminApi.OrganizationImageSnapshotSchema |
    adminApi.OrganizationImageAddedSchema |
    adminApi.OrganizationImageRemovedSchema;

export type OrganizationImageQueryEntity = {}

export interface OrganizationImageQueryState {
    entities: immutable.Map<string, OrganizationImageQueryEntity>;
}

const initialState: OrganizationImageQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: OrganizationImageQueryState,
    event: OrganizationImageQueryEventUnion,
): OrganizationImageQueryState {
    switch (event.type) {
        case "organization-image-snapshot": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const { image } of event.payload) {
                const entity = {};
                entities.set(image, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "organization-image-added": {
            let { entities } = state;

            const { image } = event.payload;
            const entity = {};

            assert(!entities.get(image));
            entities = entities.set(image, entity);

            return {
                ...state,
                entities,
            };
        }

        case "organization-image-removed": {
            let { entities } = state;

            const { image } = event.payload;

            assert(entities.has(image));

            entities = entities.delete(image);

            return {
                ...state,
                entities,
            };
        }

    }

}

//#endregion

//#region selectors

export function hasOrganizationImage(state: OrganizationImageQueryState, image: string) {
    return state.entities.has(image);
}

//#endregion
