import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type ImageQueryMemoizer = QueryMemoizer<
    ImageQueryState, ImageQueryEventUnion, []
>

export type ImageQuery = Query<ImageQueryState, ImageQueryEventUnion>

export function createImageQueryMemoizer(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): ImageQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 0);

    return memoizer;

    async function* source(
        signal: AbortSignal,
    ) {
        const source = await services.backend.getImages({
            parameters: {},
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type ImageQueryEventUnion =
    adminApi.ImageInitializedEventSchema |
    adminApi.ImageAddedEventSchema |
    adminApi.ImageRemovedEventSchema |
    adminApi.ImageUpdatedEventSchema;

export interface ImageQueryEntity {
    name: string;
    registry: string,
    repository: string,
    overcapacity: {
        factor: number,
        minimum: number,
        maximum: number,
    },
    resources: Record<string, { "request": number, "limit": number }>,
    ports?: Array<{
        type: "tcp" | "udp" | "sctp",
        container: number
    }>,
    portForwarding: boolean;
    imageReadyInterval?: number,
    lookbackInterval?: number,
    metricsPath?: string,
    defaultArgs?: Array<string>,
    defaultEnv?: Record<string, string>,
    sessionLingerInterval?: number,
    sessionSlotCapacity?: number,
    sessionSlotBuffer?: number,
    tagKeep?: number,
    imageBuffer?: number
}

export interface ImageQueryState {
    entities: immutable.Map<string, ImageQueryEntity>;
}

const initialState: ImageQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: ImageQueryState,
    event: ImageQueryEventUnion,
): ImageQueryState {
    switch (event.type) {
        case "image-initialized": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const image of event.payload.images) {
                const {
                    id,
                    name,
                    registry,
                    repository,
                    overcapacity,
                    resources,
                    ports,
                    portForwarding,
                    imageReadyInterval,
                    lookbackInterval,
                    metricsPath,
                    defaultArgs,
                    defaultEnv,
                    sessionLingerInterval,
                    sessionSlotCapacity,
                    sessionSlotBuffer,
                    tagKeep,
                    imageBuffer,
                } = image;

                const entity = {
                    name,
                    registry,
                    repository,
                    overcapacity,
                    resources,
                    ports,
                    portForwarding: portForwarding ?? false,
                    imageReadyInterval,
                    lookbackInterval,
                    metricsPath,
                    defaultArgs,
                    defaultEnv,
                    sessionLingerInterval,
                    sessionSlotCapacity,
                    sessionSlotBuffer,
                    tagKeep,
                    imageBuffer,
                };
                entities.set(id, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "image-added": {
            let { entities } = state;

            const {
                id,
                name,
                registry,
                repository,
                overcapacity,
                resources,
                ports,
                portForwarding,
                imageReadyInterval,
                lookbackInterval,
                metricsPath,
                defaultArgs,
                defaultEnv,
                sessionLingerInterval,
                sessionSlotCapacity,
                sessionSlotBuffer,
                tagKeep,
                imageBuffer,
            } = event.payload.image;
            const entity = {
                name,
                registry,
                repository,
                overcapacity,
                resources,
                ports,
                portForwarding: portForwarding ?? false,
                imageReadyInterval,
                lookbackInterval,
                metricsPath,
                defaultArgs,
                defaultEnv,
                sessionLingerInterval,
                sessionSlotCapacity,
                sessionSlotBuffer,
                tagKeep,
                imageBuffer,
            };

            assert(!entities.get(id));
            entities = entities.set(id, entity);

            return {
                ...state,
                entities,
            };
        }

        case "image-removed": {
            let { entities } = state;

            const { id } = event.payload.image;

            assert(entities.has(id));

            entities = entities.delete(id);

            return {
                ...state,
                entities,
            };
        }

        case "image-updated": {
            let { entities } = state;

            const {
                id,
                name,
                registry,
                repository,
                overcapacity,
                resources,
                ports,
                portForwarding,
                imageReadyInterval,
                lookbackInterval,
                metricsPath,
                defaultArgs,
                defaultEnv,
                sessionLingerInterval,
                sessionSlotCapacity,
                sessionSlotBuffer,
                tagKeep,
                imageBuffer,
            } = event.payload.image;

            let entity = entities.get(id);
            assert(entity);
            entity = {
                ...entity,
                name,
                registry,
                repository,
                overcapacity,
                resources,
                ports,
                portForwarding: portForwarding ?? false,
                imageReadyInterval,
                lookbackInterval,
                metricsPath,
                defaultArgs,
                defaultEnv,
                sessionLingerInterval,
                sessionSlotCapacity,
                sessionSlotBuffer,
                tagKeep,
                imageBuffer,
            };

            entities = entities.set(id, entity);

            return {
                ...state,
                entities,
            };
        }
    }

}

//#endregion

//#region selectors

export function selectImageList(state: ImageQueryState) {
    return [
        ...state.entities.
            map((value, key) => mapEntity(key, value)).
            sortBy(value => value.image).
            values(),
    ];
}

export function selectImageOptions(state: ImageQueryState) {
    return selectImageList(state).
        map(({ image, name }) => [image, name] as const);
}

export function selectImageItem(
    state: ImageQueryState,
    image: string,
) {
    const entity = state.entities.get(image);
    if (!entity) return;

    return mapEntity(image, entity);
}

export function selectImageName(state: ImageQueryState, image: string) {
    return selectImageItem(state, image)?.name;
}

function mapEntity(image: string, entity: ImageQueryEntity) {
    return {
        image,
        ...entity,
    };
}

//#endregion
