import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type SessionQueryMemoizer = QueryMemoizer<
    SessionQueryState, SessionQueryEventUnion, [string]
>

export type SessionQuery = Query<SessionQueryState, SessionQueryEventUnion>

export function createSessionQueryFactory(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): SessionQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 1);

    return memoizer;

    async function* source(
        signal: AbortSignal,
        organization: string,
    ) {
        const source = await services.backend.getSessions({
            parameters: { organization },
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type SessionQueryEventUnion =
    adminApi.SessionSnapshotEventSchema |
    adminApi.SessionCreatedEventSchema |
    adminApi.SessionDestroyedEventSchema |
    adminApi.SessionStartedEventSchema |
    adminApi.SessionStoppedEventSchema;

export interface SessionQueryEntity {
    image: string,
    tag: string;
    region: string;
    location: string;
    machine: string;
    active: boolean;
    created: number;
    host: string;
    ports?: Record<string, number>;
    labels: Record<string, string>;
    enableMetrics: boolean
}

export interface SessionQueryState {
    entities: immutable.Map<string, SessionQueryEntity>;
}

const initialState: SessionQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: SessionQueryState,
    event: SessionQueryEventUnion,
): SessionQueryState {
    switch (event.type) {
        case "session-snapshot": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const {
                session,
                image,
                tag,
                region,
                location,
                machine,
                active,
                created,
                host,
                ports,
                labels,
                enableMetrics,
            } of event.payload) {
                const entity = {
                    image,
                    tag,
                    region,
                    location,
                    machine,
                    active,
                    created,
                    host,
                    ports,
                    labels: labels ?? {},
                    enableMetrics: enableMetrics ?? false,
                };

                entities.set(session, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "session-created": {
            let { entities } = state;

            const {
                session,
                image,
                tag,
                region,
                location,
                machine,
                created,
                host,
                labels,
                enableMetrics,
            } = event.payload;
            const active = false;
            const entity = {
                image,
                tag,
                region,
                location,
                machine,
                active,
                created,
                host,
                labels: labels ?? {},
                enableMetrics: enableMetrics ?? false,
            };

            entities = entities.set(session, entity);

            return {
                ...state,
                entities,
            };
        }

        case "session-destroyed": {
            let { entities } = state;

            const { session } = event.payload;

            entities = entities.delete(session);

            return {
                ...state,
                entities,
            };
        }

        case "session-started": {
            let { entities } = state;

            const { session, ports } = event.payload;

            entities = entities.update(session, entity => {
                assert(entity);
                return {
                    ...entity,
                    ports,
                    active: true,
                };
            });

            return {
                ...state,
                entities,
            };
        }

        case "session-stopped": {
            let { entities } = state;

            const { session } = event.payload;

            entities = entities.update(session, entity => {
                assert(entity);
                return {
                    ...entity,
                    active: false,
                };
            });

            return {
                ...state,
                entities,
            };
        }
    }

}

//#endregion

//#region selectors

export function selectSessionList(state: SessionQueryState) {
    return [
        ...state.entities.
            map((value, key) => mapEntity(key, value)).
            sortBy(value => value.created).
            reverse().
            values(),
    ];
}

export function selectSessionItem(
    state: SessionQueryState,
    session: string,
) {
    const entity = state.entities.get(session);
    if (!entity) return;

    return mapEntity(session, entity);
}

export function selectSessionActive(
    state: SessionQueryState,
    session: string,
): boolean | undefined {
    return selectSessionItem(state, session)?.active;
}

function mapEntity(session: string, entity: SessionQueryEntity) {
    return {
        session,
        ...entity,
    };
}

//#endregion
