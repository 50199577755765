import * as adminApi from "@gameye/admin-api-spec";
import assert from "assert";
import { html, nothing, PropertyValues } from "lit";
import { property } from "lit/decorators.js";
import { BusyController, FormController } from "../../../controllers/index.js";
import { FieldValidators, isIterableEmpty, randomString } from "../../../utils/index.js";
import { ComponentBase } from "../../base.js";

type Model =
    adminApi.CreateApiKeyApplicationJsonRequestBodySchema

const members = [
    "token",
    "label",
] as Array<keyof Model>;

const fieldValidators = {
    ...adminApi.validateCreateApiKeyApplicationJsonRequestBodySchema.properties,
} as FieldValidators<Model>;

function hasError(model: Partial<Model>, member: keyof Model): boolean {
    const fieldValidator = fieldValidators[member];
    return model[member] != null && !isIterableEmpty(fieldValidator(model[member]));
}

export class ApiKeyAddComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    organization!: string

    private busy = new BusyController(this);

    private form = new FormController<Model>(
        this,
        members,
        hasError,
    )

    protected getModel() {
        return {
            token: randomString(20),
        };
    }

    render() {
        const { isBusy } = this.busy;
        const { model, errors } = this.form;

        if (model == null || errors == null) return nothing;

        return html`
<fieldset>

<app-text-field
    title="Token"
    data-member="token"
    .value=${model.token}
    ?error=${errors.token}
    trim
    required
></app-text-field>

<app-text-field
    title="Label"
    data-member="label"
    .value=${model.label}
    ?error=${errors.label}
    trim
    required
></app-text-field>

</fieldset>

<app-action-bar>
<app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
    <app-icon type="thumb_up"></app-icon>
    Ok
</app-clickable-action>
<app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
    <app-icon type="undo"></app-icon>
    Cancel
</app-clickable-action>
</app-action-bar>
`;
    }

    private handleSubmit = this.busy.wrap(async () => {
        const { model } = this.form;
        assert(model);

        this.form.touch();
        if (!this.form.isValid()) return;

        const result = await applicationContext.services.backend.createApiKey({
            parameters: {
                organization: this.organization,
            },
            entity: () => {
                return model as adminApi.CreateApiKeyApplicationJsonRequestBodySchema;
            },
        });

        assert(result.status === 201);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

    update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (this.form.model != null) return;

        const model = this.getModel();
        this.form.setModel(model);
    }
}
